import React from 'react'
import styled from 'styled-components'
import { color, letterSpacing } from '../shared/styles'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr;
  align-items: center;

  @media screen and (max-width: 980px) {
    grid-template-columns: 1fr;
  }

  label {
    color: ${color.white};
    justify-self: flex-end;
    margin-right: 10px;
    letter-spacing: ${letterSpacing.medium};
    align-self: ${({ type }) => type === 'textarea' ? 'flex-start' : 'center'};
    padding-top: ${({ type }) => type === 'textarea' ? '10px' : 0};

    @media screen and (max-width: 980px) {
      justify-self: flex-start;
    }
  }

  input,
  select {
    background-color: ${color.white85};
    border: none;
    padding: 10px;
    height: 50px;
    width: 100%;
  }

  textarea {
    padding: 10px;
    border: none;
    background-color: ${color.white85};
    resize: ${(props) => props.resize || 'vertical'};
    min-height: 250px;
  }
`

const Input = ({
  label,
  name,
  type,
  placeholder = '',
  value,
  onChange,
  isRequired = false,
  resize = false,
  maxLength,
  minLength,
  children,
  oninvalid
}) => {
  const renderField = () => {
    switch (type) {
      case 'textarea':
        return <textarea name={name} rows="10" cols="50" value={value} onChange={onChange} onInvalid={oninvalid} required={isRequired} maxLength={maxLength} minLength={minLength} />
      case 'select' :
        return <select name={name} onChange={onChange} onInvalid={oninvalid} placeholder={placeholder} required={isRequired}>{children}</select> 
      default:
        return <input name={name} type={type} value={value} onChange={onChange} onInvalid={oninvalid} placeholder={placeholder} required={isRequired} />
    }
  }

  return (
    <Wrapper type={type} resize={resize}>
      <label htmlFor={name}>{label}:</label>
      {renderField()}
    </Wrapper>
  )
}

export default Input
