import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import Recaptcha from 'react-google-recaptcha'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'

import Layout from '../components/layout'
import Seo from '../components/seo'
import CallToAction from '../components/CallToAction'
import { color, fontSize, lineHeight } from '../components/shared/styles'
import Input from '../components/Input'
import getTelephoneNumber from '../utils/getTelephoneNumber'
import Container from '../components/shared/Container'
import countries from '../utils/countries'

const RECAPTCHA_KEY = process.env.GATSBY_APP_SITE_RECAPTCHA_KEY
if (typeof RECAPTCHA_KEY === 'undefined') {
  throw new Error(`RECAPTCHA_KEY is undefined! https://www.netlify.com/docs/form-handling/#custom-recaptcha-2-with-your-own-settings`)
}

const encode = (data) => Object.keys(data)
  .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
  .join("&")

const TopSection = styled.section`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${props => props.bg || ''});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${color.white};
  padding: 0 50px 0 100px;

  @media screen and (max-width: 1024px) {
    padding: 50px;
  }

  @media screen and (max-width: 680px) {
    padding: 25px;
  }

  h1 {
    visibility: hidden;
  }

  .title {
    color: ${color.white};
    font-size: ${fontSize.md};
    line-height: ${lineHeight.md};
    max-width: 510px;
  }
`

const MiddleSection = styled.section`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 100px;

  @media screen and (max-width: 1350px) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }

  form {
    display: grid;
    grid-gap: 20px;
    margin-bottom: 100px;

    p {
      font-size: ${fontSize.sm};
      margin-bottom: 15px;
    }

    button {
      height: 50px;
      border: 1px solid ${color.white};
      padding: 10px 50px;
      background: transparent;
      color: ${color.white};
      width: 200px;
      justify-self: flex-end;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        background: ${color.white};
        color: ${color.black};
      }

      &[disabled] {
        cursor: not-allowed;
      }

      @media screen and (max-width: 640px) {
        width: 100%;
      }
    }
  }
`

const Title = styled.div`
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const CallToActionSection = styled.section`
  button {
    @media screen and (max-width: 630px) {
      width: 100%;
    }
  }
`

const ContactInfo = styled.div`
  padding: 0 0 0 70px;
  border-left: 1px solid ${color.white40};

  @media screen and (max-width: 1350px) {
    border-left: none;
    text-align: center;
    padding: 50px 0;
    margin-bottom: 100px;
    background: rgb(0,0,0);
    background: linear-gradient(75deg,rgba(0,0,0,0.5) 0%,rgba(0,27,7,0.5) 100%);
  }

  h2 {
    font-size: ${fontSize.sm};
    margin-bottom: 10px;
  }

  p {
    font-size: ${fontSize.sm};
    color: ${color.white};
    margin-bottom: 0;
  }

  dl {
    display: block;
    margin-bottom: 5px;
  }

  dt {
    display: inline;
    font-weight: normal;
    font-weight: 800;
  }

  dd {
    display: inline;
    margin-left: 5px;
  }

  a {
    text-decoration: none;
    color: ${color.white};
  }

  .social-title {
    font-size: 1.4rem;
    line-height: 1.5rem;
    margin-bottom: 10px;

    &:not(:first-of-type) {
      margin-top: 35px;
    }
  }

  .form-info {
    font-size: 1.5rem;
  }
`

const CaptchaContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const SocialMedia = styled.a`
  text-decoration: none;
  width: 125px;
  height: ${props => props.size || '50px'};
  background-image: url(${(props => props.iconUrl || '')});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  filter: ${(props) => props.isWhite ? 'brightness(0) invert(1)' : 'none'};

  @media screen and (max-width: 1350px) {
    width: 100%;
  }
`

const ContactUsPage = () => {
  const data = useStaticQuery(graphql`
    {
      allSanityTelephones {
        nodes {
          name
          number
          id
        }
      }
      allSanitySocialMedia {
        edges {
          node {
            id
            name
            url
            icon {
              asset {
                url
              }
            }
          }
        }
      }
      allSanityContactPage {
        nodes {
          title
          topSectionImage {
            asset {
              url
            }
          }
        }
      }
    }`
  )

  const phones = data?.allSanityTelephones?.nodes?.map(
    ({ name, number }) => ({ title: name, number })
  )

  const socialMedia = data?.allSanitySocialMedia?.edges?.map(
    ({ node: { id, name, url, icon: { asset: { url: icon } } } }) => ({ id, name, url, icon })
  )

  const title = data?.allSanityContactPage?.nodes[0]?.title

  const bgImage = data?.allSanityContactPage?.nodes[0]?.topSectionImage?.asset?.url

  const recaptchaRef = useRef()

  const [buttonDisabled, setButtonDisabled] = useState(true)

  const [formData, setFormData] = useState({
    name: '',
    country: '',
    email: '',
    telephone: '',
    message: ''
  })

  const handleChange = e => {
    setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (formData.country === '' || formData.email === '' || formData.name === '') {
      toast('Por favor llena todos los campos para enviar tu consulta');
    } else {
      const form = e.target
      const recaptchaValue = recaptchaRef.current.getValue()
  
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': form.getAttribute('name'),
          'g-recaptcha-response': recaptchaValue,
          ...formData
        })
      })
        .then(() => navigate(form.getAttribute('action')))
        .catch(error => alert(error))
    }
  }

  return (
    <Layout>
      <Seo title="Contacto" description="¿Tienes alguna duda o consulta? Contáctanos" />
      <TopSection bg={bgImage}>
        <Title>
          <h1>Contacto</h1>
          <p className="title">{title}</p>
        </Title>
        <Container>
          <MiddleSection>
            <form
              name="contact"
              method="post"
              action="/gracias"
              data-netlify="true"
              data-netlify-recaptcha="true"
              data-netlify-honeypot="feeling"
              onSubmit={handleSubmit}
            >
              <noscript>
                <p>This form won’t work with Javascript disabled</p>
              </noscript>
              <input type="hidden" name="form-name" value="contact" />
              <div hidden>
                <input type="hidden" name="feeling" />
              </div>
              <p className="form-info">Gracias por comunicarse con nosotros, nos pondremos en contacto a la brevedad.</p>
              <Input name="name" label="Nombre" type="text" value={formData.name} oninvalid="this.setCustomValidity('Introduce un nombre válido')" onChange={handleChange} isRequired={true} />
              <Input name="country" label="País" type="select" value={formData.country} oninvalid="this.setCustomValidity('Selecciona un país de la lista')" onChange={handleChange} isRequired={true}>
                <option value="">Selecciona un país</option>
                {countries.map((country, idx) => <option key={`${country.substring(0,2)}_${idx}`} value={country}>{country}</option>)}
              </Input>
              <Input name="email" label="Email" type="email" value={formData.email} oninvalid="this.setCustomValidity('Introduce un email válido')" onChange={handleChange} isRequired={true} />
              <Input name="telephone" label="Teléfono" type="text" value={formData.telephone} oninvalid="this.setCustomValidity('Introduce un número de teléfono válido')" onChange={handleChange} isRequired={true} />
              <Input name="message" label="Consulta" type="textarea" value={formData.message} oninvalid="this.setCustomValidity('Introduce tu consulta')" onChange={handleChange} maxLength={500} minLength={15} isRequired={true} />
              <CaptchaContainer>
                <Recaptcha
                  hl="es-419"
                  ref={recaptchaRef}
                  sitekey={RECAPTCHA_KEY}
                  onChange={() => setButtonDisabled(false)}
                />
              </CaptchaContainer>
              <button type="submit" disabled={buttonDisabled}>ENVIAR</button>
            </form>
            <ContactInfo>
              <p className="social-title"><strong>Teléfonos</strong></p>
              {phones?.map(({ title, number }, idx) => (
                <dl key={`phone-${idx}`}>
                  <dt><FontAwesomeIcon icon={title.toLowerCase() === 'whatsapp' ? faWhatsapp : faPhoneAlt} /> </dt>
                  <dd><a href={title === 'Llamadas' ? `tel:+${getTelephoneNumber(number)}` : `https://wa.me/${getTelephoneNumber(number)}`}>{number}</a></dd>
                </dl>
              )
              )}
              <p className="social-title"><strong>Dirección</strong></p>
              <p>Ruta 55, km 23.500.<br />Ombúes de Lavalle</p>
              <p className="social-title"><strong>Síguenos en</strong></p>
              {socialMedia && (
                socialMedia.map(social =>
                  <SocialMedia
                    key={social.id}
                    href={social.url}
                    target="_blank"
                    iconUrl={social.icon}
                    size="25px"
                    isWhite
                  />
                )
              )}
            </ContactInfo>
          </MiddleSection>
        </Container>
      </TopSection>
      <CallToActionSection>
        <CallToAction
          text="Consulta los productos disponibles"
          to="/productos"
          buttonText="VER PRODUCTOS"
        />
      </CallToActionSection>
    </Layout>
  )
}

export default ContactUsPage
