// All countries
// length 252
const countries = [
  "Afganistán",
  "Islas Aland",
  "Albania",
  "Argelia",
  "Samoa Americana",
  "Andorra",
  "Angola",
  "Anguila",
  "Antártida",
  "Antigua y Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaiyán",
  "Bahamas",
  "Bahréin",
  "Bangladesh",
  "Barbados",
  "Bielorrusia",
  "Bélgica",
  "Belice",
  "Benin",
  "islas Bermudas",
  "Bután",
  "Bolivia",
  "Bonaire, Sint Eustatius y Saba",
  "Bosnia y Herzegovina",
  "Botswana",
  "Isla Bouvet",
  "Brasil",
  "Territorio Británico del Océano Índico",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Camboya",
  "Camerún",
  "Canadá",
  "Cabo Verde",
  "Islas Caimán",
  "República Centroafricana",
  "Chad",
  "Chile",
  "porcelana",
  "Isla de Navidad",
  "Islas Cocos (Keeling)",
  "Colombia",
  "Comoras",
  "Congo",
  "Congo, República Democrática del Congo",
  "Islas Cook",
  "Costa Rica",
  "Costa de Marfil",
  "Croacia",
  "Cuba",
  "Curazao",
  "Chipre",
  "Republica checa",
  "Dinamarca",
  "Djibouti",
  "Dominica",
  "República Dominicana",
  "Ecuador",
  "Egipto",
  "El Salvador",
  "Guinea Ecuatorial",
  "Eritrea",
  "Estonia",
  "Etiopía",
  "Islas Falkland (Malvinas)",
  "Islas Faroe",
  "Fiyi",
  "Finlandia",
  "Francia",
  "Guayana Francesa",
  "Polinesia francés",
  "Territorios Franceses del Sur",
  "Gabón",
  "Gambia",
  "Georgia",
  "Alemania",
  "Ghana",
  "Gibraltar",
  "Grecia",
  "Groenlandia",
  "Granada",
  "Guadalupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guayana",
  "Haití",
  "Islas Heard y McDonald",
  "Santa Sede (Estado de la Ciudad del Vaticano)",
  "Honduras",
  "Hong Kong",
  "Hungría",
  "Islandia",
  "India",
  "Indonesia",
  "Irán (República Islámica de",
  "Irak",
  "Irlanda",
  "Isla del hombre",
  "Israel",
  "Italia",
  "Jamaica",
  "Japón",
  "Jersey",
  "Jordán",
  "Kazajstán",
  "Kenia",
  "Kiribati",
  "República de Corea, Popular Democrática de",
  "Corea, república de",
  "Kosovo",
  "Kuwait",
  "Kirguistán",
  "República Democrática Popular Lao",
  "Letonia",
  "Líbano",
  "Lesoto",
  "Liberia",
  "Jamahiriya Arabe Libia",
  "Liechtenstein",
  "Lituania",
  "Luxemburgo",
  "Macao",
  "Macedonia, la ex República Yugoslava de",
  "Madagascar",
  "Malawi",
  "Malasia",
  "Maldivas",
  "Mali",
  "Malta",
  "Islas Marshall",
  "Martinica",
  "Mauritania",
  "Mauricio",
  "Mayotte",
  "México",
  "Micronesia, Estados Federados de",
  "Moldavia, República de",
  "Mónaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Marruecos",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Países Bajos",
  "Antillas Holandesas",
  "Nueva Caledonia",
  "Nueva Zelanda",
  "Nicaragua",
  "Níger",
  "Nigeria",
  "Niue",
  "Isla Norfolk",
  "Islas Marianas del Norte",
  "Noruega",
  "Omán",
  "Pakistán",
  "Palau",
  "Territorio Palestino, Ocupado",
  "Panamá",
  "Papúa Nueva Guinea",
  "Paraguay",
  "Perú",
  "Filipinas",
  "Pitcairn",
  "Polonia",
  "Portugal",
  "Puerto Rico",
  "Katar",
  "Reunión",
  "Rumania",
  "Federación Rusa",
  "Ruanda",
  "San Bartolomé",
  "Santa elena",
  "Saint Kitts y Nevis",
  "Santa Lucía",
  "San Martín",
  "San Pedro y Miquelón",
  "San Vicente y las Granadinas",
  "Samoa",
  "San Marino",
  "Santo Tomé y Príncipe",
  "Arabia Saudita",
  "Senegal",
  "Serbia",
  "Serbia y Montenegro",
  "Seychelles",
  "Sierra Leona",
  "Singapur",
  "San Martín",
  "Eslovaquia",
  "Eslovenia",
  "Islas Salomón",
  "Somalia",
  "Sudáfrica",
  "Georgia del sur y las islas Sandwich del sur",
  "Sudán del Sur",
  "España",
  "Sri Lanka",
  "Sudán",
  "Surinam",
  "Svalbard y Jan Mayen",
  "Swazilandia",
  "Suecia",
  "Suiza",
  "República Árabe Siria",
  "Taiwan, provincia de China",
  "Tayikistán",
  "Tanzania, República Unida de",
  "Tailandia",
  "Timor-Leste",
  "Para llevar",
  "Tokelau",
  "Tonga",
  "Trinidad y Tobago",
  "Túnez",
  "pavo",
  "Turkmenistán",
  "Islas Turcas y Caicos",
  "Tuvalu",
  "Uganda",
  "Ucrania",
  "Emiratos Árabes Unidos",
  "Reino Unido",
  "Estados Unidos",
  "Islas menores alejadas de los Estados Unidos",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Islas Vírgenes Británicas",
  "Islas Vírgenes, EE. UU.",
  "Wallis y Futuna",
  "Sahara Occidental",
  "Yemen",
  "Zambia",
  "Zimbabue"
]

export default countries
